body{
  margin: 0;
  padding: 0;
}

.wrapper{
}
.container{
  width : 1280px
}
.header{
  padding: 15px 0;
  -webkit-box-shadow: 0px 2px 12px -5px rgba(0, 0, 0, 0.38);
  box-shadow:0px 2px 12px -5px rgba(0, 0, 0, 0.38);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9;
  .logo{
    margin-bottom: 0;
    float: left;
  }
  .btn{
    margin-left: 15px;
  }
}

.modal-d{
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow-y: scroll;
  z-index: 9999;
  .wrapper-modal{
    position: relative;
    display: block;
  }
  .modal-content{
    width: 60%;
    left: 20%;
    position: absolute;
    top: 7vh;
    border-radius: 10px;
    z-index: 2;
    padding: 40px 35px;
    display: block;
    background: #fff;

    &.small-modal{
      width: 30%;
      left: 35%;
      top: 15vh;
      border-radius: 20px;
      overflow: hidden;
    }
    &.b-btns{
      padding-bottom: 0;

    }
    &.no-p{
      padding: 0;
    }
    .form-group{
      margin: 15px 0;
      display: block;
      float: left;
      width: 100%;
    }
  }

  .header-modal{
    padding: 15px 25px;
    font-family: $nunito;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    i{
      margin-right: 5px;
      font-size: 16px;
      color : $bleu;
    }
  }
  .content-modal{
    padding: 35px 20px;
    h3{
      margin-bottom: 0;
    }
  }
  .footer-modal{
    border-top: 1px solid #ddd;
    float: left;
    width: 100%;
    overflow: hidden;

    .btn{
      width: 50%;
      border-radius: 0;
      text-align: center;
      padding: 15px 10px;
      font-weight: 700;
      letter-spacing: 1px;
      &:first-child{
        border-bottom-left-radius: 20px;
      }
      &:last-child{
        border-bottom-right-radius: 20px;
      }
    }
  }
  &:after{
    content: '';
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(106,106,106,.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1

  }
  .close-modal {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 15px;
    &:hover{
      color: $grey
    }
  }
  h2{
    margin: 35px 0 25px;
  }
}
*:focus {
  outline: 0 !important;
}
.btn{
  border: 0;
  outline: none;
  border-radius: 10px;
  font-family: $roboto;
  font-size: 14px;
  padding: 10px 20px;
  text-align: center;
  font-weight: 500;
  &:active{
    outline: none;
    border: 1px solid transparent!important;
  }
  &.full{
    width: 100%;
  }
  &.btn-green{
    background-color: $green;
    color: white;
    border:1px solid $green;
  }
  &.btn-yellow{
    background-color: $yellow;
    color: $black;
    border:1px solid $yellow;
  }
  &.btn-blue{
    background-color: $bleu;
    color: white;
    border:1px solid $bleu;
  }
  &.btn-white{
    background-color: white;
    color: #344054;
    border:1px solid transparent;
  }
  &.btn-full-blue{
    background-color: $bleu;
    color: white;
    border:1px solid $bleu;
    width:100%;
  }
    &.btn-full-grey{
        background-color: #E6E6E6;
        color: #000;
        border:1px solid #E6E6E6;
        width:100%;
    }
  &.btn-red{
    background-color: #F53C3C;
    color: white;
    border:1px solid #F53C3C;
  }
  &.btn-full{
    width:100%;
  }
  &.btn-grey{
    background-color: #E6E6E6;
    color: #000;
    border:1px solid #E6E6E6;
  }
  &.btn-transparent{
    background-color: transparent;
    color: $bleu;
    border:1px solid transparent;
  }
  &:focus{
    outline:none
  }
  i{
    font-size: 15px;
    margin-right: 10px;
  }
  &.icon-only{
    i{
      margin-right: 0;
    }
  }
}
.pull-right{
  float: right;
}
.btns{
  margin: 25px 0;
}
.boxes{
  margin: 45px 0;
}
.box{
  border-radius: 12px;
  border : 1px solid #DBE3FF;
  padding: 35px 15px;
  background-color: #F4F6FE;
  margin: 15px 0;
  display: block;
  h3{
    margin-top: 15px;
    margin-bottom: 0;
  }
}
.bg-icon{
  background-color: $bleu;
  padding: 6px 8px;
  border-radius: 8px;
  color: white;
}
.hand{
  margin-left: 5px;
  img{
    width: 28px;

  }
}
.back-btn{
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
}
.grid-form{
  margin: 25px 0
}
.input-1{
  background-color: #F4F6FE;
  border-radius: 12px;
  padding: 20px 15px 20px 45px;
  position: relative;
  margin: 15px 0;
  input{
    width: 100%;
    outline: none;
    border: 0;
    background-color: transparent;
  }
  .icon-i{
    position: absolute;
    left: 20px;
    top: 26px;
    color:$grey;
    font-size: 16px;
  }
}
.list-1{
  padding-left: 0;
  list-style: none;
  margin: 30px 0;
  li{
    padding: 10px 0;
    label{
      color : #1C1C1C;
      margin-left: 15px;
      font-size: 14px;
    }
  }
}
.search-bar{
  position: relative;
  margin: 15px 0;
  input{
    display: block;
    padding: 10px 15px 10px 45px ;
    border-radius: 12px;
    border:1px solid $grey;
    width: 100%;
  }
  span{
    position: absolute;
    top:13px;
    left: 15px;
    color:$bleu
  }
}
.list-items{
  list-style: none;
  padding-left: 0;
  margin: 15px 0;
    padding-bottom: 150px;
  li{
    background-color: #F4F6FE;
    border-radius: 12px;
    padding: 10px 15px;
    position: relative;
    margin-bottom: 10px;
    p{
      margin-bottom: 5px;
    }
    .delete-btn{
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
.bottom-bar{
  background-color: #fff;
  padding: 20px 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #f7f7f7;
}
.usrp-fb-1{
    bottom: 170px;
}
.btn-send{
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 15px;
    top: 10px;
    color:$bleu;
    font-size: 30px;
    &[disabled]{
        opacity: .5;
    }
}
.btndown{
    margin-top: 30vh;
}
