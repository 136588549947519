@media screen and (max-width: 1380px) {
  .container{
    max-width: 100%;
    padding: 0 15px;
  }


}

@media screen and (max-width: 767px) {

    .modal-d{
        .modal-content{
            &.small-modal{
                width: 90%;
                left: 5%;
            }
        }
    }

}
