
$nunito : 'Nunito Sans', sans-serif;
$roboto : "Roboto", sans-serif;
$logoColor : #0E73AC;
$yellow : #F9B200;
$black : #000;
$bleu : #3C65F5;
$red : #F53C3C;
$grey :#8B8B8B;
$green :#00ABA4;
$orange :#FF7C1C;
body{
  font-family: $nunito;
  background-color: #fff;
}
h1{
  color: $black;
  font-size: 34px;
}
h2{
  font-size: 20px;
  margin: 25px 0 35px;
  font-family: $nunito;
  font-weight: 700;
}
h3{
  color: $black;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: $nunito;
  font-weight: 700;
}
h4{
  color: #7A7A7A;
  font-size: 14px;
  margin-bottom: 15px;
  font-family: $nunito;
  font-weight: 700;
}
p{
  font-size: 14px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}
a{
  text-decoration: none;
  color: $bleu;
}
.logo{
  margin-top: 35px;
}
.bold{
  font-weight: 700;
}
.small-title{
  color: $bleu;
  font-size: 16px;
  margin: 35px 0 25px;
  position: relative;
}
.sub-title{
  color: $bleu;
  font-size: 20px;
  position: relative;
  margin: 15px 0 30px;
}
.white{
  color:white
}
.bleu{
  color: $bleu
}
.red{
  color: $red;
}
.green{
  color: $green!important;
}
.grey{
  color : $grey!important;
}
.black{
  color: $black
}
.orange{
  color: $orange
}
.button{
  padding: 8px 16px;
  color: $black;
  border: 0;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  &:focus{
    outline: none
  }
  &.btn-red{
    background-color: #e86e5b;
    color: white;
    border: 1px solid #e86e5b;
  }
  &.btn-yellow{
    background-color: $yellow;
    border: 1px solid $yellow;
    &:hover{
      -webkit-box-shadow: inset 2px 2px 10px 10px rgba(199,162,52,1);
      -moz-box-shadow: inset 2px 2px 10px 10px rgba(199,162,52,1);
      box-shadow: inset 2px 2px 10px 10px rgba(199,162,52,1);
    }
  }
  &.btn-bleu{
    background-color: $bleu;
    border: 1px solid $bleu;
    color: white;
    &:hover{
      -webkit-box-shadow: inset 2px 2px 10px 10px rgba(0,0,0,.1);
      -moz-box-shadow: inset 2px 2px 10px 10px rgba(0,0,0,.1);
      box-shadow: inset 2px 2px 10px 10px rgba(0,0,0,.1);
    }
  }
  &.btn-white{
    color: $black;
    border: 1px solid $black;

  }
  &[disabled]{
    background-color: #ddd;
    border: 1px solid #ddd;
  }
  &.btn-white{
    background-color: white;
    border: 1px solid #1F8389;
    border-radius: 10px;
    &:hover{
      background-color:#E3F3FC;
      border:1px solid #E3F3FC;
      color:#0E73AC;

    }
    &[disabled]{
      background-color: #ddd;
      border: 1px solid #ddd;
    }
  }
}
.black{
  color: $black
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
textarea{
  border: 1px solid #6A6A6A;
  border-radius: 5px;width: 100%;display: block;
  outline: none;
  padding: 5px 10px;
  outline: none;
}
form input{
  border: 1px solid #6A6A6A;
  border-radius: 5px;width: 100%;display: block;
  outline: none;
  padding: 5px 10px;
}
.row-input {
  label{
    margin: 20px 0 10px;
    width: 100%;
    display: block;
    .label-p{
      font-size: 16px;
      color: $bleu;
      display: block;
    }
    .label-req{
      color: #6A6A6A;
      font-size: 14px;
      display: block;
    }
  }
  input.error{
    border: 1px solid red
  }
}
.fright{
  float: right;
}
.fleft{
  float: left;
}
.align-right{
  text-align: right;
}
.dblock{
  width: 100%;
  float: left;
}
section{
  width: 100%;
  display: block;
}
.styled-checkbox{
  & + label{
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 0;
    line-height: 17px;
    padding: 12px 15px;
    cursor: pointer;
  }
  &:hover{
    background-color: $bleu;
    color: #fff;
  }
  input{
    display: none;
  }
  input:checked + label{
    background-color: $bleu;
    color: white
  }

}

.styled-checkbox label{
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 0;
  line-height: 17px;
  padding: 12px 15px;
  cursor: pointer;
}
.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
  input{
    display: none     }
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range, .custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.loader-holder{
  position: fixed;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background-color: rgba(255,255,255,.9);
  z-index: 99;
  .loader-wrapper{
    position: absolute;
    top:35%;
    left: 48%;

    .lds-hourglass {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-hourglass:after {
      content: " ";
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 8px;
      box-sizing: border-box;
      border: 32px solid $bleu;
      border-color: $bleu transparent $bleu transparent;
      animation: lds-hourglass 1.2s infinite;
    }

  }
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
table{
  width: 100%;
  th{
    border-bottom: 1px solid $black
  }
  th,td{
    padding:5px;
  }
  td{
    border-bottom: 1px solid #ddd
  }
}
.cta{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.text-right{
  text-align: right;
}
.mt_15{
  margin-top: 15px;
}
.legends{
  margin: 15px 0;
  text-align: center;
  .legend{
    margin: 0 10px;
    font-family: $roboto;
    font-size:14px;
    font-weight: 500;
    &:before{
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    &.theorique{
      &:before{
        border:1px solid $bleu;
        width: 30px;
      }
    }
    &.saxo{
      &:before{
        border:1px solid $orange;
        width: 30px;
      }
    }
    &.reel{
      &:before{
        background-color: $bleu;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}

